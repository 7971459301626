import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngTokenomicsDevelopment = loadable(() => import("../components/TokenomicsDevelopment/english.js"));
const ArabicTokenomicsDevelopment = loadable(() => import("../components/TokenomicsDevelopment/arabic.js"));
const ChineseTokenomicsDevelopment = loadable(() => import("../components/TokenomicsDevelopment/chinese.js"));

const schema = {
    "@context": "http://schema.org/",
    "@graph": [
        {
            "@type": "product",
            "image": "https://softtik.com/images/nft-collection/invest-section-22.webp",
            "name": "Leading Tokenomics Consulting & Design Company",
            "description": "Softtik Technologies is the top tokenomics consulting and design company with experienced consultants. We can help you create a top-notch economic model.",
            "brand": "Softtik Technologies",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "121"
            }
        },
        {
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "What is Tokenomics in cryptocurrency?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>Tokenomics can be seen as a balanced economic model that represents the business plan of the crypto project while taking into account the interest of all parties involved. Tokenomics shows the financial part of each participant in the project, from the investor to the founder.</p>"
                }
            }, {
                "@type": "Question",
                "name": "How do you become a Tokenomics Expert?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>A person must have expertise in micro and macroeconomics along with technical knowledge of blockchain, smart contracts, and cryptocurrencies. You can start practicing by developing economic models for projects. Usually, the whitepaper of the crypto projects has tokenomics details.</p>"
                }
            }, {
                "@type": "Question",
                "name": "Where can I find Tokenomics?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "<p>The Crypto market is filled with projects, and a record number of projects are added to the ecosystem daily. Most crypto projects have a whitepaper that contains all the tokenomics details. You can read the whitepaper to learn about the project's business model.</p>"
                }
            }
            ]
        }
    ]
}

export class TokenomicsDevelopment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/tokenomics-development-services/"
                        title="Tokenomics Consulting & Design Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-22.webp"
                        description="Softtik Technologies is the top tokenomics consulting and design company with experienced consultants. We can help you create a top-notch economic model."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicTokenomicsDevelopment />
                                    : lang == "China"
                                        ? <ChineseTokenomicsDevelopment />
                                        : <EngTokenomicsDevelopment />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default TokenomicsDevelopment;